import React, { useEffect, useState } from 'react'
import './Header.css'
import { useExtensionInstalled, chromeWebstoreURL } from 'services/useExtension'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import useAuth, { GoogleOneTapLogin, useForceReloadAfterLogin, showGoogleOneTapLogin } from 'services/useAuth'
import { useAction } from 'services/actions'

const tabs = [
  { uri: '/', label: 'Home' },
  { uri: '/chat', label: 'Chat' },
  { uri: '/actions', label: 'Actions' },
]

function logout() {
  document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  document.cookie = `accessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  window.location.reload()
}

export default function Header() {
  const extensionInstalled = useExtensionInstalled()
  const navigate = useNavigate()
  const user = useAuth()
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  useForceReloadAfterLogin()

  // get react router param
  const { id } = useParams();
  const action = useAction(id)
  let isActionTab = false
  if (action && !action.loading && !action.isAssistant) {
    isActionTab = true    
  }

  useEffect(() => {
    if (!user) {
      window.google.accounts.id.prompt()
    }
  }, [user])

  tabs.forEach(tab => {
    if (tab.uri === location.pathname) tab.class = 'tab selected'
    else if (tab.uri === '/' && location.pathname.startsWith('/chat/') && !isActionTab) tab.class = 'tab selected'
    else if (tab.uri === '/actions' && isActionTab) tab.class = 'tab selected'
    else tab.class = 'tab'
  })

  return (
    <div className="main-header">
    <header>
      {!user?.isLoading && !user && <GoogleOneTapLogin />}
      <span className="header-title">
        <a href="/">Chrome Autopilot</a>
        {extensionInstalled && ' ☑️' }
      </span>
      <div className="tab-bar">
        {tabs.map((tab, i) => (
          <div key={i} className={tab.class} onClick={() => navigate(tab.uri)}>{tab.label}</div>
        ))}
      </div>

        {!user && (
          <div className="user-info">
            <div className="user-login" onClick={() => showGoogleOneTapLogin()}>Sign in</div>
            <span className="user-menu-icon" onClick={toggleMenu}>⋮</span>
          </div>
        )}
        {user && !user.isLoading && (
          <div className="user-info" onClick={toggleMenu}>
            <img alt='' className="user-avatar" src={user?.picture} width={30} />
            <span className="user-name">{user?.name}</span>
            <span className="user-menu-icon" onClick={toggleMenu}>⋮</span>
          </div>
        )}
        {showMenu && (
          <div className="context-menu">
            {user?.plan === 'paid' && <a href="/billing" className="context-menu-item">My subscription</a>}
            {user && user.plan !== 'paid' && (  
              <a href="/checkout" className="context-menu-item">Upgrade to Premium</a>
            )}
            <a href={chromeWebstoreURL} className="context-menu-item">Chrome Web Store</a>
            <a href="/terms-of-service" className="context-menu-item">Terms of Service</a>
            <a href="/privacy-policy" className="context-menu-item">Privacy Policy</a>
            <a href="https://github.com/chromeautopilot/actions" className="context-menu-item">Developer API</a>
            {user && <div className="context-menu-item logout" onClick={logout}>Log out</div>}
          </div>
        )}
    </header>
    </div>
  )
}