import Footer from "components/Footer";
import Header from "components/Header";
import './styles.css';
import useAuth, { googleClientId, onOauth2Success, useForceReloadAfterLogin } from "services/useAuth";
import { chromeWebstoreURL, useExtensionInstalled } from "services/useExtension";
import { useState, useEffect } from "react";

export default function Home() {
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: googleClientId,
    scope: 'openid email profile',
    callback: onOauth2Success,
  });

  const handleLogin = () => {
    client.requestAccessToken();
  }

  const user = useAuth()
  
  const extensionInstalled = useExtensionInstalled()
  useForceReloadAfterLogin()

  // every second change the value of taskName
  const taskNames = ['navigate', 'send', 'scrape', 'reply', 'submit', 'post', 'click', 'copy', 'type', 'draft', 'summarize', 'sign in', 'schedule', 'locate', 'trade', 'buy', 'sell', 'register', 'publish', 'extract', 'respond', 'translate', 'unsubscribe', 'pay', 'request']
  const [taskName, setTaskName] = useState(taskNames[0])
  let n = 1
  useEffect(() => {
    const interval = setInterval(() => {
      setTaskName(taskNames[n])
      n = (n + 1) % taskNames.length
    }, 750)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Header />

      <div className="content">
        <h1 className="heroText">
          <span>The AI assistant that can <span className="taskName">{taskName}.</span></span>
        </h1>
        <div className="subline">
          Chat with your AI assistant and watch it complete 
          the assignment in your browser.
        </div>

        {!user && (
          <section className="heroButton">
            <div className="signInGoogle" onClick={handleLogin}></div>
            <div>No credit card needed -- try for free!</div>
          </section>
        )}
        {user && !extensionInstalled && (
          <section className="heroButton">
            <div className="get-button">
              <a href={chromeWebstoreURL}>Get Chrome Autopilot →</a>
            </div>
            <div>On the next page, click 'Add to Chrome' to install the Chrome Extension</div>
          </section>
        )}
        {user && extensionInstalled && (!user.plan || user.plan === 'free') && (
          <section className="heroButton">
            <div className="get-button">
              <a href='/checkout'>✨ Upgrade to Premium</a>
            </div>
            <div>Subscribe for $20/month</div>
          </section>
        )}


        <section className="bg">
          <div>
            
          </div>
        </section>
          
      </div>
      <Footer />
    </>
  )
}

