// import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { useAPI } from './api';
import { state, useVenti } from 'venti';
import { getMessages } from './assistantMessages';


const getAccessToken = () => document.cookie.split('; ').find(row => row.startsWith('accessToken='))

export default function useAuth() {
  const accessToken = getAccessToken()
  const { data: user, isLoading } = useAPI(accessToken && '/v1/me')
  if (isLoading) return { isLoading }
  return user
}

export function useForceReloadAfterLogin() {
  const state = useVenti()
  state.get('lastLogin')
}

export const googleClientId = '419610695706-5kpuku8d5ckutikj0odvj5svlgrl4c9u.apps.googleusercontent.com'

export async function onOauth2Success(response) {
  return onLoginSuccess(response)
}

export function showGoogleOneTapLogin() {
  const google = window.google;
  google.accounts.id.prompt((notification) => {
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      document.cookie =  `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      google.accounts.id.prompt()
    }
  });
}

export function onLoginSuccess(googleUser) {
  console.log('onLoginSuccess', googleUser)
  const idToken = googleUser.credential
  const accessToken = googleUser.access_token
  fetch('/v1/login-with-google', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ idToken, accessToken })
  })
  .then(res => res.json())
  .then(user => {
    // force reload chat screen and header
    state.set('messages', [])
    const actionId = window.location.pathname.split('/').pop()
    if (actionId) getMessages(actionId)
    state.set('lastLogin', Date.now())
  })
  // TODO: refresh header in case user changed
}

export function GoogleOneTapLogin() {

  window.google.accounts.id.initialize({
    client_id: googleClientId,
    callback: onLoginSuccess,
  });

  // useGoogleOneTapLogin({
  //   googleAccountConfigs: {
  //     client_id: googleClientId,
  //     callback: onLoginSuccess,
  //   },
  // });
  return null
}