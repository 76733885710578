import './style.css'

export default function Footer() {
  const year = new Date().getFullYear()
  return <>
    <footer style={{ clear: 'both' }}>
      &copy; {year} Gadget Labs LLC 
      <span>|</span>
      <a href="/terms-of-service">Terms of Service</a>
      <span>|</span>
      <a href="/privacy-policy">Privacy Policy</a>
      <span>|</span>
      <a href="https://github.com/chromeautopilot/actions">Developer API</a>
      <span>|</span>
      <a href="/billing">Manage Account</a>
    </footer>
  </>
}