import { useState, useEffect } from 'react'
import { useVenti, state } from 'venti'

export async function importAction(id ) {
  if (!id) throw new Error('Action `id` is required')
  const origin = window.location.origin
  const isLocalhost = origin.includes('localhost')
  const modulePath = `https://chromeautopilot.com/actions/${id}.js`
  if (!isLocalhost) {
    return import(/* webpackIgnore: true */ modulePath)
  }
  // console.log(`${origin}/macros/${id}.js?${Date.now()}`)
  return import(/* webpackIgnore: true */ `${origin}/actions/${id}.js?${Date.now()}`)
    // .catch(err => {
    //   console.log(err)
    //   if (!err.message.includes('fetch')) throw err
    //   return import(/* webpackIgnore: true */ modulePath).catch()
    // })
}

export function useAction(id) {
  const [action, setAction] = useState(null)
  useEffect(() => {
    async function fetchAndSetAction() {
      if (id) {
        const fetchedAction = await importAction(id)
        setAction(fetchedAction)
      } else {
        setAction({ isAssistant: true })
      }
    }
    fetchAndSetAction()
  }, [id]) // Re-run the effect when `id` changes
  return action
}

const ACTIONS_URI = '/v1/actions'

export async function getActions() {
  return fetch(ACTIONS_URI)
    .then(res => res.json())
    .then(({ actions }) => {
      state.set('actions', actions)
    })
}

export function useActions({ assistantsOnly = false }) {
  const state = useVenti()
  const actions = state.get('actions', [])
  if (!actions.length) getActions()
  return actions.map(action => {
    const isAssistant = action.name.toLowerCase().includes('assistant')
    if (assistantsOnly && !isAssistant) return null
    if (!assistantsOnly && isAssistant) return null
    return action
  }).filter(Boolean)
}